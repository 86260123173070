import React, { useState } from "react";
import { Link, Route, Routes, useNavigate, useLocation  } from "react-router-dom";
import styles from "./css/Client.module.css";
import { HeaderClient } from "../shared/Header";
import { Receipts } from "./Receipts";
import { FooterPublic } from "../shared/Footer";
import { Profile } from "./Profile";
import { UploadReceipt } from "./UploadReceipt";
import { api, projectUuid, raffleId } from "../../index";

export const Client = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState(false);

  const clearCookies = () => {
    // Получаем все cookies
    const cookies = document.cookie.split(";");

    // Удаляем все cookies
    cookies.forEach((cookie) => {
      const cookieName = cookie.split("=")[0].trim();
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  };

  const signout = () => {
    clearCookies();
    fetch(`${api}/auth/signout/`, {
      credentials: "include",
    })
      .then((r) => {
        clearCookies();
        navigate("/");
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className={styles.client}>
      <HeaderClient
        authCallback={(auth) => {
          setIsAdmin(auth.isAdmin);
        }}
      />
      <section className={styles.public}>
        <div className={styles.container}>
          <h1 className={styles.h1}>Личный профиль</h1>
          <p className={styles.subtitle}>Загрузить чеки можно до 14 января</p>
          <div className={styles.block}>
            <div className={styles.menu}>
              {isAdmin > 0 && (
                <Link
                to={`/admin/project/${projectUuid}/raffles/${raffleId}/receipts`}
                className={
                  location.pathname ===
                  `/admin/project/${projectUuid}/raffles/${raffleId}/receipts`
                    ? styles.activeLink
                    : undefined
                }
                >
                  Админ
                </Link>
              )}
              <Link to="/client"
                className={
                  location.pathname === "/client" ? styles.activeLink : undefined
                }>Мой профиль</Link>
              <Link to="/client/receipts/add"
                className={
                  location.pathname === "/client/receipts/add"
                    ? styles.activeLink
                    : undefined
                }>Загрузка чека</Link>
              <Link to="/client/receipts"
                className={
                  location.pathname === "/client/receipts"
                    ? styles.activeLink
                    : undefined
                }>Мои чеки</Link>
              <button onClick={signout}>Выход</button>
            </div>
            <div className={styles.content}>
              <Routes>
                <Route path="/" element={<Profile />} />
                <Route path="/receipts" element={<Receipts />} />
                <Route path="/receipts/add" element={<UploadReceipt />} />
              </Routes>
            </div>
          </div>
        </div>
      </section>
      <FooterPublic />
    </div>
  );
};
