import React, { useState } from 'react';
import styles from "./css/UploadReceipt.module.css";
import { api, raffleId } from "../../index";
import { useNavigate } from "react-router-dom";

export const UploadReceipt = () => {
    const navigate = useNavigate();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);

    const handleFileChange = (e) => {
        const validFormats = ["image/jpeg", "image/jpg", "image/png", "image/heic"];
        const maxFileSize = 10 * 1024 * 1024; // 10 MB
        const maxTotalSize = 20 * 1024 * 1024; // 20 MB
        const maxFilesPerUpload = 2; // Максимум 2 файла за один раз
    
        // Проверяем, не превышает ли количество файлов за раз допустимого лимита
        if (e.target.files.length > maxFilesPerUpload) {
            alert(`Можно загрузить не более ${maxFilesPerUpload} изображений за раз.`);
            return; // Прекращаем выполнение функции
        }
    
        // Текущий суммарный размер уже загруженных файлов
        let totalSize = selectedFiles.reduce((acc, fileObj) => acc + fileObj.file.size, 0);
    
        // Проверяем каждый файл
        const filesArray = [];
        for (const file of e.target.files) {
            if (!validFormats.includes(file.type)) {
                alert("Неверный формат файла. Допустимы только JPEG, JPG, PNG, HEIC.");
                return; // Выходим из функции
            }
    
            if (file.size > maxFileSize) {
                alert(`Файл "${file.name}" превышает допустимый размер в 10 MB.`);
                return; // Выходим из функции
            }
    
            if (totalSize + file.size > maxTotalSize) {
                alert("Суммарный размер всех файлов не должен превышать 20 MB.");
                return; // Выходим из функции
            }
    
            totalSize += file.size;
            filesArray.push({
                file,
                preview: URL.createObjectURL(file),
            });
        }
    
        // Если есть валидные файлы, добавляем их
        if (filesArray.length > 0) {
            setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
        }
    };
    
    
    

    const handleRemoveFile = (index) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        setIsLoading(true);  // Начинаем показывать прелоадер
        const formData = new FormData();
        selectedFiles.forEach(({ file }) => {
            formData.append('image[]', file);
        });
        formData.append('raffleId', `${raffleId}`);

        try {
            const response = await fetch(`${api}/receipts/`, {
                method: 'POST',
                credentials: 'include',
                body: formData
            });
            const data = await response.json();
            console.log('Response from server:', data);
            setSelectedFiles([]);
            navigate('/client/receipts');
        } catch (error) {
            console.error('Error uploading files:', error);
        } finally {
            setIsSubmitting(false);
            setIsLoading(false);  // Скрываем прелоадер после завершения
        }
    };

    const clearFiles = () => {
        setSelectedFiles([]);
    };

    return (
        <section className={styles.newReceipt}>
            <h1 className={styles.h1}>Мои данные</h1>
            <p className={styles.subtitle}>Можно загрузить две фотографии, если</p>
            <p className={styles.subtitle}>на одной чек не помещается</p>

            <div
                className={styles.form}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                    e.preventDefault();
                    handleFileChange(e);
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" height="72px" viewBox="0 -960 960 960" width="72px" fill="#000">
                    <path
                        d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/>
                </svg>

                {selectedFiles.length > 0 && (
                    <ul className={styles.fileList}>
                        {selectedFiles.map((fileObj, index) => (
                            <li key={index} className={styles.fileItem}>
                                <img
                                    src={fileObj.preview}
                                    alt="Preview"
                                    className={styles.previewImage}
                                />
                                <br/>
                                <div className="hc-stack">
                                    <span className={styles.fileName}>{fileObj.file.name}</span>
                                    <button
                                        className={styles.removeButton}
                                        onClick={() => handleRemoveFile(index)}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <br/>
                                <br/>
                            </li>
                        ))}
                    </ul>
                )}

                {isLoading && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <circle cx="20" cy="20" r="18" stroke="#333" strokeWidth="4" strokeLinecap="round" />
                        <circle cx="20" cy="20" r="18" stroke="#e63946" strokeWidth="4" strokeLinecap="round" strokeDasharray="113.097" strokeDashoffset="113.097">
                            <animate attributeName="stroke-dashoffset" values="113.097;0" dur="2s" keyTimes="0;1" repeatCount="indefinite" />
                        </circle>
                    </svg>
                )}

                <label className={styles.photoReceipt} htmlFor="">Перетащите сюда фото чека или выберите файл</label>
                <div className={styles.formats}>JPEG, JPG, PNG, HEIC до 10 MB</div>

                <input
                    id="file"
                    type="file"
                    accept=".png, .jpg, .jpeg, .heic"
                    multiple
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />

                <div className={styles.buttons}>
                    <label htmlFor="file" className={styles.select}>Выбрать</label>
                    <button
                        className={styles.send}
                        onClick={handleSubmit}
                        disabled={isSubmitting || selectedFiles.length === 0}
                    >
                        Отправить
                    </button>
                    <button
                        className={styles.clear}
                        onClick={clearFiles}
                        disabled={isSubmitting || selectedFiles.length === 0}
                    >
                        Очистить
                    </button>
                </div>
            </div>
        </section>
    );
};