import React from 'react';
import styles from './css/Prizes.module.css';
import image4 from '../../img/image44.webp';
import image5 from '../../img/sert.webp';

export const Prizes = () => {
    return (
        <section id="prizes" className={styles.prizes}>
            <h2 className={styles.title}>Призы</h2>
            <div className={styles.prizeBlock}>
                <div className={styles.prizeCard}>
                    <span>Главный приз</span>
                    <img className={styles.prizeCar} src={image4} alt="auto"/>
                    <div className={styles.prizeCarBl}>
                    <h3>Автомобиль BELGEE X50</h3>
                    <p>Розыгрыш 17 января 2025 года</p></div>
                </div>
                <div className={styles.prizeCard}>
                    <span>160 призов</span>
                    <img className={styles.prizeSert} src={image5} alt="sertificate"/>
                    <div className={styles.prizeCarBl}>
                    <h3>Сертификаты «Корона»</h3>
                    <p>Каждую неделю розыгрыш 20 сертификатов</p></div>
                </div>
            </div>
        </section>
    );
};
