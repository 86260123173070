import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToAnchor = () => {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.querySelector(hash);

            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            // Если хэша нет, прокручиваем к началу страницы
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [hash]);

    return null;
};
