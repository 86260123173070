import React, { useState, useEffect } from 'react';
import styles from './css/AcceptModal.module.css';
import {api} from "../../index";

const AcceptModal = ({
                         images,
                         error,
                         isOpen,
                         onClose,
                         onConfirm,
                         message,
                         code,
                         setCode,
                         receiptSum,
                         setReceiptSum
                     }) => {
    const [validationError, setValidationError] = useState('');
    const [isChecking, setIsChecking] = useState(false);

    const checkCodeValidation = async (codeToValidate) => {
        setIsChecking(true);
        try {
            const response = await fetch(`${api}/receipt/validate/`, {
                method: 'POST',
                body: JSON.stringify({ code: codeToValidate }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            if (data.exists) {
                setValidationError('Код чека уже существует');
            } else {
                setValidationError('');
            }
        } catch (error) {
            setValidationError('Ошибка при проверке кода');
        } finally {
            setIsChecking(false);
        }
    };

    // Функция для обработки изменений в поле ввода
    const handleCodeChange = (e) => {
        const newCode = e.target.value;
        setCode(newCode);  // Обновляем код
        if (newCode.length >= 1) {  // Проверяем код, когда он не пустой и длинее 1 символа
            checkCodeValidation(newCode);
        } else {
            setValidationError('');  // Если код слишком короткий, сбрасываем ошибку
        }
    };

    // Функция для обработки изменений в поле ввода
    const handleSumChange = (e) => {
        const newSum = e.target.value;
        setReceiptSum(newSum);  // Обновляем код
    };

    useEffect(() => {
        if (code && code.length >= 1) {  // Проверяем код, когда он не пустой и длиннее 1 символа
            checkCodeValidation(code);
        } else {
            setValidationError('');  // Если код слишком короткий, сбрасываем ошибку
        }
    }, [code]);

    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div
                className={styles.modal}
                onClick={(e) => e.stopPropagation()}
            >
                <p>{message}</p>
                {images.split(";").map((image, index) => (
                    // <a
                    //     key={index}  // или используйте уникальный идентификатор, если он есть
                    //     href={`/${image.replaceAll("../", "")}`}
                    //     data-fancybox={`accept-modal-${id}`}
                    // >
                        <img className={styles.preview} alt="" src={`/${image.replaceAll("../", "")}`} />
                    // </a>
                ))}

                {/*<a*/}
                {/*    href={img}*/}
                {/*    data-fancybox="gallery-receipt"*/}
                {/*    data-caption={code || 'Предпросмотр чека'}*/}
                {/*>*/}
                {/*    <img className={styles.preview} src={img} alt="Предпросмотр чека"/>*/}
                {/*</a>*/}

                <p style={{color: 'red'}}>{error}</p>
                <input
                    type="text"
                    placeholder="Код чека"
                    value={code}
                    onChange={handleCodeChange}  // Используем функцию для обработки изменения
                />
                {validationError && <p style={{color: 'red'}}>{validationError}</p>}

                <input
                    type="number"
                    placeholder="Сумма товаров по акции с учетом скидки"
                    value={receiptSum}
                    onChange={handleSumChange}  // Используем функцию для обработки изменения
                />
                {receiptSum && <p style={{color: 'green'}}>Будет добавлено игровых
                    кодов: <b>{Math.floor(Math.floor(receiptSum) / 2)}</b></p>}

                <div className={styles.controls}>
                    <button onClick={onClose}>Закрыть</button>
                    <button onClick={onConfirm} disabled={isChecking || validationError}>
                        Принять
                    </button>
                </div>

                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="48px"
                    viewBox="0 -960 960 960"
                    width="48px"
                    fill="#fff"
                    style={{
                        position: "absolute",
                        right: "24px",
                        top: "24px",
                        cursor: "pointer"
                    }}
                    onClick={onClose}
                >
                    <path
                        d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
                    />
                </svg>
            </div>
        </div>
    );
};

export default AcceptModal;