import React from 'react';
import styles from './css/DeclineModal.module.css';

const reasons = [
    {
        label: 'Нет фото чека',
        text: 'Изображение Кассового чека отсутствует. Загрузите повторно на Интернет-сайт через личный кабинет фотографию регистрируемого Кассового чека. Изображение Кассового чека должно быть чётким и содержать: - сведения о номере документа; - наименование продавца (торгового объекта, где были приобретены Игровые продукты), учетный номер плательщика (УНП); - дату и время продажи Игровых продуктов; - наименование Игровых продуктов; - количество и стоимость Игровых продуктов; - итоговую сумму покупки. Кассовые чеки без наличия информации, указанной в настоящем пункте, либо без наличия Игровых продуктов, либо с наличием Игровых продуктов на сумму меньше 2,00 (Двух) белорусских рублей 00 копеек, не могут быть использованы для участия в Рекламной игре.'
    },
    {
        label: 'Не полная информация',
        text: 'Ваш чек был отклонен по причине не соответствия п. 10.1.6. Правил Рекламной игры.  Изображение кассового чека загружено не полностью. Загрузите повторно на Интернет-сайт через личный кабинет фотографию регистрируемого Кассового чека. Изображение Кассового чека должно быть чётким и содержать: - сведения о номере документа; - наименование продавца (торгового объекта, где были приобретены Игровые продукты), учетный номер плательщика (УНП); - дату и время продажи Игровых продуктов; - наименование Игровых продуктов; - количество и стоимость Игровых продуктов; - итоговую сумму покупки. Кассовые чеки без наличия информации, указанной в настоящем пункте, либо без наличия Игровых продуктов, либо с наличием Игровых продуктов на сумму меньше 2,00 (Двух) белорусских рублей 00 копеек, не могут быть использованы для участия в Рекламной игре.'
    },
    {
        label: 'Не верная дата чека (покупка совершена ДО начала или ПОСЛЕ окончания РИ)',
        text: 'Ваш чек был отклонен по причине не соответствия п. 10.1.1. Правил Рекламной игры. Покупка совершена не в установленный правилами Рекламной игры период.'
    },
    {
        label: 'Загружен НЕ чек',
        text: 'Ваш чек был отклонен по причине не соответствия п. 10.1.6. Правил Рекламной игры. Загружена фотография не чека.   Загрузите повторно на Интернет-сайт через личный кабинет фотографию регистрируемого Кассового чека. Изображение Кассового чека должно быть чётким и содержать: - сведения о номере документа; - наименование продавца (торгового объекта, где были приобретены Игровые продукты), учетный номер плательщика (УНП); - дату и время продажи Игровых продуктов; - наименование Игровых продуктов; - количество и стоимость Игровых продуктов; - итоговую сумму покупки. Кассовые чеки без наличия информации, указанной в настоящем пункте, либо без наличия Игровых продуктов, либо с наличием Игровых продуктов на сумму меньше 2,00 (Двух) белорусских рублей 00 копеек, не могут быть использованы для участия в Рекламной игре.'
    },
    {
        label: 'В чеке вообще нет игровых продуктов',
        text: 'Ваш чек был отклонен по причине не соответствия п. 10.1.1. Правил Рекламной игры.  В чеке отсутствуют Игровые продукты.'
    },
    {
        label: 'Плохое качество фото чека',
        text: 'Ваш чек был отклонен по причине не соответствия п. 10.1.6. Правил Рекламной игры. Загружена нечёткая фотография чека. Загрузите повторно на Интернет-сайт через личный кабинет фотографию регистрируемого Кассового чека. Изображение Кассового чека должно быть чётким и содержать: - сведения о номере документа; - наименование продавца (торгового объекта, где были приобретены Игровые продукты), учетный номер плательщика (УНП); - дату и время продажи Игровых продуктов; - наименование Игровых продуктов; - количество и стоимость Игровых продуктов; - итоговую сумму покупки. Кассовые чеки без наличия информации, указанной в настоящем пункте, либо без наличия Игровых продуктов, либо с наличием Игровых продуктов на сумму меньше 2,00 (Двух) белорусских рублей 00 копеек, не могут быть использованы для участия в Рекламной игре.'
    },
    {
        label: 'Повторная регистрация чека одобренного',
        text: 'Попытка повторной регистрации одобренного чека. Ваш чек был отклонен по причине не соответствия п. 10.5. Правил Рекламной игры. Данные из 1 (одного) Кассового чека, которые регистрирует Участник, принимаются к участию в Рекламной игре один раз.'
    },
    {
        label: 'Покупка совершена НЕ в ТС «КОРОНА»',
        text: 'Ваш чек был отклонен по причине не соответствия п. 10.1.1. Правил Рекламной игры. Покупка совершена не в магазине розничной торговой сети «КОРОНА», расположенном на территории Республики Беларусь.'
    },
    {
        label: 'Не достаточная сумма игровых продуктов',
        text: 'Ваш чек был отклонен по причине не соответствия п. 10.1.6. и п.10.2. Правил Рекламной игры. Покупка совершена не на установленную правилами Рекламной игры сумму. Кассовые чеки с наличием Игровых продуктов на сумму менее 2,00 (Двух) белорусских рублей 00 копеек (с учётом всех скидок, действующих на момент расчета), не могут быть использованы для участия в Рекламной игре.'
    }
];

const DeclineModal = ({images, isOpen, onClose, openAcceptModal, onConfirm, message, declineReason, setDeclineReason}) => {
    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modal}
                 onClick={(e) => e.stopPropagation()}
            >
                <p>{message}</p>

                {images.split(";").map((image, index) => (
                    // <a
                    //     key={index}  // или используйте уникальный идентификатор, если он есть
                    //     href={`/${image.replaceAll("../", "")}`}
                    //     data-fancybox={`decline-modal-${id}`}
                    // >
                        <img className={styles.preview} alt="" src={`/${image.replaceAll("../", "")}`} />
                    // </a>
                ))}

                {/*<img className={styles.preview} src={img} alt=""/>*/}

                <select onChange={(e) => setDeclineReason(e.target.value)} defaultValue="">
                    <option value="" disabled>Выберите причину</option>
                    {reasons.map(reason => (
                        <option key={reason.value} value={reason.text}>
                            {reason.label}
                        </option>
                    ))}
                </select>

                <textarea
                    className={styles.textarea}
                    placeholder="Причина отклонения"
                    value={declineReason}
                    onChange={(e) => setDeclineReason(e.target.value)}
                    rows={1}
                />

                <div className={styles.controls}>
                    <button onClick={onClose}>
                        Закрыть
                    </button>
                    <button onClick={onConfirm}>
                        Отклонить
                    </button>
                </div>

                {/*<button className={styles.transition} onClick={() => {*/}
                {/*    onClose();*/}
                {/*    openAcceptModal();*/}
                {/*}}>Перейти на принятие*/}
                {/*</button>*/}

                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="48px"
                    viewBox="0 -960 960 960"
                    width="48px"
                    fill="#fff"
                    style={{
                        position: "absolute",
                        right: "24px",
                        top: "24px",
                        cursor: "pointer"
                    }}
                    onClick={onClose}
                >
                    <path
                        d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
                    />
                </svg>
            </div>
        </div>
    );
};

export default DeclineModal;