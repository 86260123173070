import React, { useEffect, useState, useCallback } from "react";
import styles from "./css/Receipts.module.css";
import { api, raffleId } from "../../index";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export const Receipts = () => {
  const [receipts, setReceipts] = useState([]);

  const fetchReceipts = useCallback(() => {
    fetch(`${api}/receipts/client/?id=${raffleId}`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => setReceipts(data))
      .catch((err) => console.error("Error fetching receipts:", err));
  }, []);

  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {});
    fetchReceipts();
  }, [fetchReceipts]);

  const raffles = [
    {
      start: new Date("2024-11-20T00:00:00"),
      end: new Date("2024-11-26T23:59:59"),
      raffle: new Date("2024-11-29T12:30:00"),
      name: "Розыгрыш 29.11.2024",
    },
    {
      start: new Date("2024-11-27T00:00:00"),
      end: new Date("2024-12-03T23:59:59"),
      raffle: new Date("2024-12-06T12:30:00"),
      name: "Розыгрыш 06.12.2024",
    },
    {
      start: new Date("2024-12-04T00:00:00"),
      end: new Date("2024-12-10T23:59:59"),
      raffle: new Date("2024-12-13T12:30:00"),
      name: "Розыгрыш 13.12.2024",
    },
    {
      start: new Date("2024-12-11T00:00:00"),
      end: new Date("2024-12-17T23:59:59"),
      raffle: new Date("2024-12-20T12:30:00"),
      name: "Розыгрыш 20.12.2024",
    },
    {
      start: new Date("2024-12-18T00:00:00"),
      end: new Date("2024-12-24T23:59:59"),
      raffle: new Date("2024-12-27T12:30:00"),
      name: "Розыгрыш 27.12.2024",
    },
    {
      start: new Date("2024-12-25T00:00:00"),
      end: new Date("2024-12-31T23:59:59"),
      raffle: new Date("2025-01-03T12:30:00"),
      name: "Розыгрыш 03.01.2025",
    },
    {
      start: new Date("2025-01-01T00:00:00"),
      end: new Date("2025-01-07T23:59:59"),
      raffle: new Date("2025-01-10T12:30:00"),
      name: "Розыгрыш 10.01.2025",
    },
    {
      start: new Date("2025-01-08T00:00:00"),
      end: new Date("2025-01-14T23:59:59"),
      raffle: new Date("2025-01-17T12:30:00"),
      name: "Розыгрыш 17.01.2025",
    },
    // {
    //   start: new Date("2024-11-20T00:00:00"),
    //   end: new Date("2025-01-14T23:59:59"),
    //   raffle: new Date("2025-01-17T13:00:00"),
    //   name: "Главный розыгрыш 17.01.2025",
    // },
  ];

  const getRaffleDate = (createdDate) => {
    const created = new Date(createdDate.replace(" ", "T"));
    const raffle = raffles.find(
      (raffle) => created >= raffle.start && created <= raffle.end
    );

    if (!raffle) {
      return raffles[0].raffle; // Возвращаем первый розыгрыш
    }

    return raffle.raffle;
  };

  return (
    <section className={styles.receipts}>
      <h1 className={styles.h1}>Статус моих чеков</h1>

      <div className={styles.gridTableContainer}>
        {(receipts ?? []).map((receipt) => (
          <div className={styles.gridTable} key={receipt.id}>
            <div className={styles.gridTableHeader}>Дата загрузки</div>
            <div className={styles.gridTableHeader}>Фото чека</div>
            <div className={styles.gridTableHeader}>Дата розыгрыша</div>
            <div className={styles.gridTableHeader}>Статус чека</div>
            <div className={styles.gridTableHeader}>Приз</div>

            <div className={styles.gridTableCell} data-label="Дата загрузки">
              {receipt.created}
            </div>
            <div className={styles.gridTableCell} data-label="Фото чека">
              <div>
                {receipt.image.split(";").map((image, index) => (
                  <a
                    key={index}
                    className={styles.receipt}
                    href={`${api}/receipts/${image}`}
                    data-fancybox={`gallery-${receipt.id}`}
                    data-caption={receipt.code ?? "-"}
                  >
                    <img alt="" src={`${api}/receipts/${image}`} />
                  </a>
                ))}
              </div>
            </div>
            <div className={styles.gridTableCell} data-label="Дата розыгрыша">
              {getRaffleDate(receipt.created) ? (
                <>
                  {getRaffleDate(receipt.created).toLocaleString()}, <br />
                  17.01.2025 в 13:00
                </>
              ) : (
                "-"
              )}
            </div>
            <div className={styles.gridTableCell} data-label="Статус чека">
              {receipt.isAccepted === 1
                ? "Одобрен"
                : receipt.isAccepted === 0
                ? "Модерация"
                : "Отклонен"}
            </div>
            <div className={styles.gridTableCell} data-label="Приз">
              {receipt.prize ?? "-"}
            </div>

            {(receipt.declineReason ?? "").length > 0 && (
              <>
                <b className={styles.emptyRow} style={{ fontSize: 12 }}>
                  Причина отклонения:{" "}
                </b>
                <div className={styles.declineReason}>
                  {receipt.declineReason ?? ""}
                </div>
              </>
            )}

            {receipt.isAccepted === 1 && receipt.receiptCodes.length > 0 && (
              <>
                <b className={styles.emptyRow} style={{ fontSize: 12 }}>
                  Игровые коды:{" "}
                </b>
                <div className={styles.emptyRow}>
                  {(receipt.receiptCodes ?? [])
                    .sort((a, b) => a.id - b.id)
                    .map((receiptCode) => (
                      <span key={receiptCode.id}>{receiptCode.id}</span>
                    ))}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};
