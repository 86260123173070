import React, { useCallback, useEffect, useState } from 'react';
import styles from './css/Export.module.css';
import { Link, useParams } from 'react-router-dom';
import { api } from '../../index';
import * as XLSX from 'xlsx';

export const Export = () => {
    const { raffleId, projectUuid } = useParams();
    const [data, setData] = useState({});

    const fetchReceipts = useCallback(() => {
        fetch(`${api}/export/members/?projectUuid=${projectUuid}&raffleId=${raffleId}`, {
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data);
            })
            .catch((err) => console.error('Error adding project:', err));
    }, [projectUuid, raffleId]);

    useEffect(() => {
        fetchReceipts();
    }, [fetchReceipts]);

    // Функция для печати данных
    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.open();
        printWindow.document.write(`
      <html>
      <head>
        <title>Print Receipts</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
          }
          table {
            width: 100%;
            border-collapse: collapse;
          }
          th, td {
          font-size: .7rem;
            border: 1px solid #ddd;
            padding: 6px;
            box-sizing: border-box;
            text-align: left;
          }
        </style>
      </head>
      <body>
        <h1>Экспорт участников розыгрыша: ${data.raffleName}</h1>
        <table>
          <thead>
            <tr>
              <th>Код розыгрыша</th>
              <th>Имя участника</th>
              <th>Телефон</th>
              <th>Код чека</th>
              <th>Почта</th>
              <th>Зарегистрирован</th>
              <th>Принят</th>
            </tr>
          </thead>
          <tbody>
            ${data.receiptCodes.map((receiptCode) => `
              <tr>
                <td>${receiptCode.id}</td>
                <td>${receiptCode.lastName} ${receiptCode.firstName} ${receiptCode.middleName || ''}</td>
                <td>${receiptCode.phone}</td>
                <td>${receiptCode.code}</td>
                <td>${receiptCode.email}</td>
                <td>${new Date(receiptCode.receiptCreated).toLocaleString()}</td>
                <td>${new Date(receiptCode.acceptedAt).toLocaleString()}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>
      </body>
      </html>
    `);
        printWindow.document.close();
        printWindow.print();
    };

    // Функция для экспорта в Excel
    const handleExportToExcel = () => {
        const exportData = data.receiptCodes.map((receiptCode) => ({
            "Код розыгрыша": receiptCode.id,
            "Имя участника": `${receiptCode.lastName} ${receiptCode.firstName} ${receiptCode.middleName || ''}`,
            "Телефон": receiptCode.phone,
            "Код чека": receiptCode.code,
            "Почта": receiptCode.email,
            "Зарегистрирован": new Date(receiptCode.receiptCreated).toLocaleString(),
            "Принят": new Date(receiptCode.acceptedAt).toLocaleString()
        }));

        const ws = XLSX.utils.json_to_sheet(exportData);

        // Автоматическая настройка ширины колонок
        const columnWidths = [];

        // Для каждого столбца определяем максимальную длину строки
        exportData.forEach(row => {
            Object.keys(row).forEach((key, idx) => {
                const cellValue = String(row[key]);
                if (!columnWidths[idx] || cellValue.length > columnWidths[idx]) {
                    columnWidths[idx] = cellValue.length;
                }
            });
        });

        ws["!cols"] = columnWidths.map(width => ({ wch: width }));

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Receipts");
        XLSX.writeFile(wb, `receipts_${data.raffleName}.xlsx`);
    };

    return (
        <div className={styles.export}>
            <h1 className={styles.h1}>Экспорт участников розыгрыша {data.raffleName}</h1>

            <div className={styles.controls}>
                <Link className={styles.back} to={`/admin/project/${projectUuid}/raffles/${raffleId}/receipts`}>Вернуться к розыгрышу</Link>
                <button className={styles.exportButton} onClick={handlePrint}>Печать данных</button>
                <button className={styles.exportButton} onClick={handleExportToExcel}>Экспорт в Excel</button>
            </div>

            <div className={styles.table}>
                {(data.receiptCodes ?? []).map((receiptCode) => (
                    <div className={styles.row} key={receiptCode.id}>
                        <div className={styles.id}>{receiptCode.id}</div>
                        <div className={styles.user}>
                            {receiptCode.lastName} {receiptCode.firstName} {receiptCode.middleName}
                        </div>
                        <div className={styles.phone}>{receiptCode.phone}</div>
                        <div className={styles.code}>{receiptCode.code}</div>
                        <div className={styles.email}>{receiptCode.email}</div>
                        <div className={styles.receiptCreated}>
                            {new Date(receiptCode.receiptCreated).toLocaleString()}
                        </div>
                        <div className={styles.acceptedAt}>
                            {new Date(receiptCode.acceptedAt).toLocaleString()}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};